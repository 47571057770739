import React from 'react'
import { graphql } from 'gatsby'
import { Footer } from '../common/footer'
import { Navigation } from '../common/navigation'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { CMSWrapper } from '../components/cmsWrapper'
import { StationOfWeek } from '../components/stations/stationOfWeek'
import { StaticBlogs } from '../components/blogs/staticBlogs'
import { StaticReferenceModules } from '../components/references/staticReferenceModules'
import { AREA_CARE_PATH } from '../config/constants'
import { Button } from '../common/button'
import { StaticVideo } from '../components/staticSections'

export default function Index({ pageContext, data }) {
  const areaPageReferences = data.allReference.nodes
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Pflege" />
      <main>
        <Navigation />
        <header className="bg-img-blue">
          <div className="row align-items-center">
            <div className="col-md-5 offset-md-1 col-12 text-center">
              <h1>
                <object
                  className="mt-5 pt-5"
                  data="../img/jukebox_logo_slogan.svg"
                  type="image/svg+xml"
                  width="480"
                  height="auto"
                  aria-label="Jukebox Slogan"
                ></object>
              </h1>
              <div id="header-buttons" className="row align-items-center">
                <div className="col-md-6 col-12">
                  <Button color="red" href="/pflege/spielen">
                    Matchen
                  </Button>
                  <small>Welcher Bereich passt zu dir?</small>
                </div>
                <div className="col-md-6 col-12">
                  <Button color="yellow" href={`${AREA_CARE_PATH}/stationen`}>
                    Stöbern
                  </Button>
                  <small>Entdecke unsere Stationen</small>

                  <Button color="green" href="#stuermen">
                    Bewerben
                  </Button>
                  <small>Komm in unser Team</small>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-12 text-center">
              <img
                className="header-illustration m-0"
                src="/img/pflege/illustration-pflege.png"
                alt="Illustration: Pfleger duo"
              />
            </div>
          </div>
        </header>

        <section className="text-center">
          <div className="row align-items-center">
            <div className="col-10 offset-1">
              <h2>Moin und willkommen in unserer jukebox!</h2>
              <p>
                Hier bist du genau richtig, wenn du die vielfältigen
                Einsatzmöglichkeiten im Universitätsklinikum Hamburg-Eppendorf
                (UKE) kennenlernen möchtest. Finde heraus, welcher Bereich am
                besten zu dir passt – viel Spaß beim Stöbern!
              </p>
              <StaticVideo
                video={{
                  url: 'https://player.vimeo.com/video/923663750?h=e83a07c5a3',
                }}
              />
            </div>
          </div>
        </section>

        <section className="bg-img-red divider-right text-center">
          <div className="row align-items-center">
            <div className="col-md-5 offset-md-1 col-10 offset-1">
              <img
                src="/img/handlettering/handlettering-wer-was-wo-willst-du-morgen-sein.svg"
                alt="Handlettering: Wer, was, wo willst du morgen sein"
                width="400"
              />
            </div>
            <div className="col-md-5 col-12">
              <h3>Nur ein paar Fragen bis zur Erkenntnis.</h3>
              <h4 className="mb-20">Welche Stationen des UKE passen zu dir?</h4>
              <Button color="red" href="/pflege/spielen">
                Matchen
              </Button>
            </div>
          </div>
        </section>

        <section className="bg-img-yellow divider-left text-center">
          <div className="row align-items-center">
            <div className="col-md-5 offset-md-1 col-12">
              <div className="image-container image-container--static image-container--frame">
                <img
                  className="w-100"
                  src="/img/stoebern-image.jpg"
                  alt="Wer wir sind"
                />
              </div>
              <br />
              <Button
                color="yellow"
                href={`${AREA_CARE_PATH}/stationen`}
                className="mt-4"
              >
                Stöbern
              </Button>
            </div>
            <div className="col-md-5 offset-md-0 col-10 offset-1 mt-4">
              <img
                src="/img/handlettering/handlettering-100-stationen-100-teams-100-chancen-unsere-pflegevielfalt.svg"
                alt="Handlettering: 100 Stationen, 100 Teams, 100 Chancen - Unsere Pflegevielfalt"
                title="100 Stationen, 100 Teams, 100 Chancen - Unsere Pflegevielfalt"
                width="400"
              />
              <h4>
                Schau dich um in den Bereichen
                <br />
                und entdecke dein Lieblingsteam.
              </h4>
            </div>
          </div>
        </section>
        {pageContext.stationOfWeek && (
          <StationOfWeek data={pageContext.stationOfWeek} />
        )}

        <section className="bg-blue divider-left pb-5">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-12 text-center">
              <h2 className="fw-bold">Arbeiten 5.0: Wann und wie es zu euch passt.</h2>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-md-5 offset-md-1 col-12">
              <div className="image-container image-container--static image-container--frame">
                <img
                  className="w-100"
                  src="/img/uke-jukebox-arbeiten-5-0.webp"
                  alt="Wer wir sind"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-md-5 col-12">
              <p>Wir bieten euch größtmögliche Flexibilisierung der Arbeitszeiten in der Pflege und optimieren die Prozesse zwischen den Ärzt:innen und Pflegenden. Das gibt es nur so bei uns!</p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-10 offset-md-1 col-12 text-center">
              <Button href="https://www.uke.de/karriere/berufe-jobs-im-uke/pflege-funktionsdienst/arbeiten-5-0.html" color="yellow" target="_blank">Jetzt dabei sein und Pflege gestalten</Button>
            </div>
          </div>
        </section>

        <section className="bg-img-yellow divider-right pb-5">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <div className="row text-center align-items-center">
                <div className="col-md-6 col-12">
                  <img
                    src="/img/handlettering/handlettering-es-geht-um-Leben-und-TOD.svg"
                    alt="Handlettering"
                    title="Es geht um Leben und Tod"
                    width="400"
                    loading="lazy"
                  />
                </div>
                <div className="col-md-6 col-12">
                  <h3 className="mt-3">
                    Im UKE kannst du die Vielfalt von 12 unterschiedlichen
                    Intensivstationen erleben.
                  </h3>

                  <Button
                    color="yellow"
                    href="/intensivmedizin"
                    className="mt-4"
                  >
                    Zur Intensivpflege
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-img-yellow divider-right pb-5">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <div className="row text-center align-items-center">
                <div className="col-md-6 col-12">
                  <img
                    src="/img/handlettering/handlettering-bei-uns-bleibt-nicht-alles-beim-alten.png"
                    alt="Handlettering"
                    title="Bei uns bleibt nicht alles beim alten"
                    width="400"
                    loading="lazy"
                  />
                </div>
                <div className="col-md-6 col-12">
                  <h3 className="mt-3">
                    Im UKE kannst du mit einem Examen in der Altenpflege in über
                    50 Bereichen arbeiten.
                  </h3>
                  <Button
                    color="yellow"
                    href={`${AREA_CARE_PATH}/altenpflegende`}
                    className="mt-4"
                  >
                    Zur Altenpflege
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {pageContext.indexPageBlogs.length > 0 && (
          <StaticBlogs
            sliderId="indexPageBlogs"
            blogsData={pageContext.indexPageBlogs}
            headline="Die neuesten Blogeinträge"
          />
        )}
        <section id="stuermen" className="bg-img-green divider-right">
          <div className="row align-items-center text-center">
            <div className="col-md-3 offset-md-1 col-12">
              <img
                src="/img/illustration-pfleger-stuermen.png"
                alt="Illustration: Pfleger einzeln"
                height="311"
                width="145"
              />
            </div>
            <div className="col-md-4 offset-md-0 col-10 offset-1">
              <img
                src="/img/handlettering/handlettering-ich-pflege-lasst-mich-durch.svg"
                alt="Handlettering: Ich pflege lasst mich durch!"
                width="400"
              />
            </div>
            <div className="col-md-3 col-12">
              <h4 className="mb-4">
                Du weißt, was du willst:
                <br />
                <strong>Komm in unser Team.</strong>
              </h4>
              <Button
                color="green"
                href="mailto:bewerbung-jukebox@uke.de?subject=Pflege-Initiativbewerbung über die jukebox"
              >
                Bewerben
              </Button>
            </div>
          </div>
        </section>
        <StaticReferenceModules referencesData={areaPageReferences} />
        <Footer />
      </main>
    </CMSWrapper>
  )
}

export const query = graphql`
  query CareReferencesQuery($referenceType: [String]) {
    allReference(
      filter: { types: { in: $referenceType }, isDraft: { eq: false } }
    ) {
      nodes {
        _id
        content {
          sections {
            _template
            bgColor
            grids {
              _template
              columns {
                _template
                altText
                description
                descriptionAlignment
                showFrame
                filename
                listStyle
                text
                textAlign
                url
                images {
                  _template
                  altText
                  description
                  filename
                  showFrame
                }
              }
              itemAlignment
              marginType
            }
          }
        }
        mediaDir
        priority
        slug
        title
      }
    }
  }
`
