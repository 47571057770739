import React from 'react'
import { StaticImage } from '../staticSections'
import { Button } from '../../common/button'

export function StationOfWeek({ data }) {
  return (
    <section
      id="station-der-woche"
      className="bg-img-yellow divider-right pb-5"
    >
      <div className="row align-items-center">
        <div className="col-md-10 offset-md-1 col-12">
          <h2 className="text-center fw-bold">Station der Woche</h2>
          <div className="row text-center align-items-center">
            <div className="col-md-6 col-12">
              {data.header.handlettering && (
                <StaticImage image={data.header.handlettering} />
              )}
              <div>
                <Button color="yellow" href={data.slug}>
                  Lerne uns kennen
                </Button>
              </div>
            </div>
            <div className="col-md-6 col-12 mt-4">
              {data.header.image && <StaticImage image={data.header.image} />}
              <h3 className="mt-3">{data.header.title}</h3>
              <h4>{data.header.introText}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
